<template>
  <v-btn :left="!$vuetify.rtl" :loading="loggingOut" :right="$vuetify.rtl" bottom fixed text @click="logout">
    <v-icon>mdi-logout-variant</v-icon>
    {{ $vuetify.lang.t('$vuetify.pages.home.drawerMenu.signOut') }}
  </v-btn>
</template>

<script>
export default {
  name: 'KurccAppBarNavigationDrawerLogOut',
  data () {
    return {
      loggingOut: false
    }
  },
  methods: {
    logout () {
      this.loggingOut = true
      this.$store.dispatch('logout').finally(() => {
        this.loggingOut = false
      })
    }
  }
}
</script>
